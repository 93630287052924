import request from '@/utils/request'

const VERSION = process.env.VUE_APP_VERSION

export function createorder(data) {
  return request({
    url: VERSION + '/order/create',
    method: 'post',
    params: data
  })
}
export function delorder(data) {
  return request({
    url: VERSION + '/order/close',
    method: 'put',
    params: data
  })
}
export function confirmorder(data) {
  return request({
    url: VERSION + '/order/confirm',
    method: 'put',
    params: data
  })
}
export function selectorder(data) {
  return request({
    url: VERSION + '/order/pay',
    method: 'post',
    params: data
  })
}
