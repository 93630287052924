import request from '@/utils/request'

const VERSION = process.env.VUE_APP_VERSION

export function login(data) {
  return request({
    url: VERSION + '/user/login',
    method: 'post',
    params: data
  })
}
export function loginout() {
  return request({
    url: VERSION + '/user/logout',
    method: 'post'
  })
}
export function register(data) {
  return request({
    url: VERSION + '/user/register',
    method: 'post',
    params: data
  })
}

export function resetpwd(data) {
  return request({
    url: VERSION + '/user/resetpwd',
    method: 'put',
    params: data
  })
}
