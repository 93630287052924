import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/layout/layout'
import profileLayout from '@/components/layout/profileLayout'

Vue.use(VueRouter)
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
const routes = [
  {
    path: '/',
    //name: 'index',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/Index') }]
  },
  {
    path: '/login',
    //name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('../views/CourseNotLogin.vue')
  },
  {
    path: '/catalogs',
    //name: 'catalogs',
    component: Layout,
    children: [
      { path: '/', component: () => import('../views/CourseLogin') },
      { path: 'course', component: () => import('../views/CourseNotLogin') },
      { path: 'shikan', component: () => import('../views/shikanCourse') }
    ],
    hidden: false
  },
  {
    path: '/exam',
    // name: 'exam',
    component: Layout,
    children: [
      { path: '/', component: () => import('../views/Test') },
      { path: 'exercise', component: () => import('../views/Exercise') },
      { path: 'subject', component: () => import('../views/ExerciseSubject') },
      { path: 'examitem', component: () => import('../views/exam') }
    ],
    hidden: false
  },
  {
    path: '/news',
    //name: 'news',
    component: Layout,
    children: [
      { path: '/', component: () => import('../views/news') },
      { path: 'detail', component: () => import('../views/newsDetail') }
    ],
    hidden: false
  },
  {
    path: '/newszx',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/newszx') }],
    hidden: false
  },
  {
    path: '/qrcode',
    //name: 'code',
    component: Layout,
    children: [
      { path: '/', component: () => import('../views/QrCode') },
      { path: 'video', component: () => import('../views/QrcodeVideo') }
    ],
    hidden: false
  },
  {
    path: '/space',
    //name: 'space',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/newsPicture') }],
    hidden: false
  },
  // {
  //   path: '/video',
  //   //name: 'video',
  //   component: Layout,
  //   children: [
  //     { path: '/', component: () => import('../views/dropsVideo') },
  //     { path: 'info', component: () => import('../views/videoInfo') }
  //   ],
  //   hidden: false
  // },
  {
    path: '/progress',
    //name: 'progress',
    component: profileLayout,
    children: [
      { path: '/', component: () => import('../views/LearnProcess') },
      { path: 'mycourses', component: () => import('../views/MyCourses') },
      { path: 'profile', component: () => import('../views/PersonalDetails') },
      { path: 'orderlist', component: () => import('../views/Myorder') },
      { path: 'testrecords', component: () => import('../views/TestRecords') },
      { path: 'settings', component: () => import('../views/AccountSettings') },
      { path: 'addlicence', component: () => import('../views/AddLicence') }
    ],
    hidden: false
  },
  {
    path: '/applytext',
    //name: 'applytext',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/applyText') }],
    hidden: false
  },
  {
    path: '/download',
    //name: 'download',
    component: () => import('../views/download')
  },
  {
    path: '/bookvideo',
    //name: 'bookVideo',
    component: () => import('../views/bookCodeVideo')
  },
  {
    path: '/qrCodeRegister',
    //name: 'qrCodeRegister',
    component: () => import('../views/qrCodeRegister')
  },
  {
    path: '/about',
    //name: 'about',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/about') }]
  },
  {
    path: '/contactus',
    //name: 'contactUs',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/contactUs') }]
  },
  {
    path: '/copyright',
    //name: 'CopyrightStatement',
    component: Layout,
    children: [
      { path: '/', component: () => import('../views/CopyrightStatement') }
    ]
  },
  {
    path: '/disclaimer',
    //name: 'disclaimer',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/disclaimer') }]
  },
  {
    path: '/useragreement',
    //name: 'UserAgreement',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/UserAgreement') }]
  },
  {
    path: '/privacypolicy',
    //name: 'PrivacyPolicy',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/PrivacyPolicy') }]
  },
  {
    path: '/servicecenter',
    //name: 'ServiceCenter',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/ServiceCenter') }]
  },
  {
    path: '/register',
    //name: 'registrationPage',
    component: () => import('../views/registrationPage')
  },
  {
    path: '/orderresult',
    //name: 'registrationPage',
    component: () => import('../views/orderresult')
  },
  {
    path: '/guangdong',
    //name: 'registrationPage',
    component: () => import('../views/GuangdongImg')
  },
  {
    path: '/exercise',
    //name: 'ServiceCenter',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/Exercise') }]
  },
  {
    path: '/results',
    //name: 'ServiceCenter',
    component: Layout,
    children: [{ path: '/', component: () => import('../views/Results') }]
  },
  {
    path: '/scan',
    //name: 'ServiceCenter',
    component: () => import('../views/Qr')
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
