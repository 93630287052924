import Cookies from 'js-cookie'

const LicenceName = 'vue_admin_template_licence_name'
const LicenceId = 'vue_admin_template_licence_id'
const SubjectId = 'vue_admin_template_subject_id'
const ParentId = 'vue_admin_template_parent_id'
const Result = 'vue_admin_template_result'
const Idcard = 'vue_admin_template_idcard'
const Password = 'vue_admin_template_password'
const Checked = 'vue_admin_template_checked'
const Term = 'vue_admin_template_term'
const Termi = 'vue_admin_template_termi'
const Uuid = 'vue_admin_template_uuid'
const IsPay = 'vue_admin_template_IsPay'
const LxcollectLicenceId = 'vue_admin_template_lx_collect_licence_id'
const LxcollectSubjectId = 'vue_admin_template_lx_collect_subject_id'
const LxLicenceId = 'vue_admin_template_lx_licence_id'
const LxSubjectId = 'vue_admin_template_lx_subject_id'
const LxIsSelect = 'vue_admin_template_lx_isselect'
const LxWrongAnswer = 'vue_admin_template_lx_wronganswer'
const LxCorrectAnswer = 'vue_admin_template_lx_correctanswer'
const LxQuestionId = 'vue_admin_template_lx_questionid'
const LxcollectQuestionId = 'vue_admin_template_lx_collect_questionid'

const KscollectLicenceId = 'vue_admin_template_ks_collect_licence_id'
const KscollectSubjectId = 'vue_admin_template_ks_collect_subject_id'
const KsLicenceId = 'vue_admin_template_ks_licence_id'
const KsSubjectId = 'vue_admin_template_ks_subject_id'
const KsIsSelect = 'vue_admin_template_ks_isselect'
const KsWrongAnswer = 'vue_admin_template_ks_wronganswer'
const KsCorrectAnswer = 'vue_admin_template_ks_correctanswer'
const KsQuestionId = 'vue_admin_template_ks_questionid'
const KscollectQuestionId = 'vue_admin_template_ks_collect_questionid'

export function getLicenceName() {
  return Cookies.get(LicenceName)
}

export function setLicenceName(licencename) {
  return Cookies.set(LicenceName, licencename)
}

export function removeLicenceName() {
  return Cookies.remove(LicenceName)
}

export function getLicenceId() {
  return Cookies.get(LicenceId)
}

export function setLicenceId(licenceid) {
  return Cookies.set(LicenceId, licenceid)
}

export function removeLicenceId() {
  return Cookies.remove(LicenceId)
}

export function getSubjectId() {
  return Cookies.get(SubjectId)
}

export function setSubjectId(subjectid) {
  return Cookies.set(SubjectId, subjectid)
}

export function removeSubjectId() {
  return Cookies.remove(SubjectId)
}

export function getParentId() {
  return Cookies.get(ParentId)
}

export function setParentId(parentid) {
  return Cookies.set(ParentId, parentid)
}

export function removeParentId() {
  return Cookies.remove(ParentId)
}

export function getResult() {
  return Cookies.get(Result)
}

export function setResult(result) {
  return Cookies.set(Result, result)
}

export function removeResult() {
  return Cookies.remove(Result)
}

export function getIdcard() {
  return Cookies.get(Idcard)
}

export function setIdcard(idcard) {
  return Cookies.set(Idcard, idcard)
}

export function removeIdcard() {
  return Cookies.remove(Idcard)
}

export function getPassword() {
  return Cookies.get(Password)
}

export function setPassword(password) {
  return Cookies.set(Password, password)
}

export function removePassword() {
  return Cookies.remove(Password)
}

export function getChecked() {
  return Cookies.get(Checked)
}

export function setChecked(checked) {
  return Cookies.set(Checked, checked)
}

export function removeChecked() {
  return Cookies.remove(Checked)
}

export function getTerm() {
  return Cookies.get(Term)
}

export function setTerm(term) {
  return Cookies.set(Term, term)
}

export function removeTerm() {
  return Cookies.remove(Term)
}

export function getTermi() {
  return Cookies.get(Termi)
}

export function setTermi(termi) {
  return Cookies.set(Termi, termi)
}

export function removeTermi() {
  return Cookies.remove(Termi)
}

export function getUuid() {
  return Cookies.get(Uuid)
}

export function setUuid(uuid) {
  return Cookies.set(Uuid, uuid)
}

export function removeUuid() {
  return Cookies.remove(Uuid)
}

export function getIsPay() {
  return Cookies.get(IsPay)
}

export function setIsPay(ispay) {
  return Cookies.set(IsPay, ispay)
}

export function removeIsPay() {
  return Cookies.remove(IsPay)
}

export function getLxLicenceId() {
  return Cookies.get(LxLicenceId)
}

export function setLxLicenceId(lxlicenceid) {
  return Cookies.set(LxLicenceId, lxlicenceid)
}

export function removeLxLicenceId() {
  return Cookies.remove(LxLicenceId)
}

export function getLxSubjectId() {
  return Cookies.get(LxSubjectId)
}

export function setLxSubjectId(lxsubjectid) {
  return Cookies.set(LxSubjectId, lxsubjectid)
}

export function removeLxSubjectId() {
  return Cookies.remove(LxSubjectId)
}

export function getLxIsSelect() {
  return Cookies.get(LxIsSelect)
}

export function setLxIsSelect(lxisselect) {
  return Cookies.set(LxIsSelect, lxisselect)
}

export function removeLxIsSelect() {
  return Cookies.remove(LxIsSelect)
}

export function getLxWrongAnswer() {
  return Cookies.get(LxWrongAnswer)
}

export function setLxWrongAnswer(lxwronganswer) {
  return Cookies.set(LxWrongAnswer, lxwronganswer)
}

export function removeLxWrongAnswer() {
  return Cookies.remove(LxWrongAnswer)
}

export function getLxCorrectAnswer() {
  return Cookies.get(LxCorrectAnswer)
}

export function setLxCorrectAnswer(lxcorrectanswer) {
  return Cookies.set(LxCorrectAnswer, lxcorrectanswer)
}

export function removeLxCorrectAnswer() {
  return Cookies.remove(LxCorrectAnswer)
}

export function getLxQuestionId() {
  return Cookies.get(LxQuestionId)
}

export function setLxQuestionId(lxquestionid) {
  return Cookies.set(LxQuestionId, lxquestionid)
}

export function removeLxQuestionId() {
  return Cookies.remove(LxQuestionId)
}

export function getLxcollectLicenceId() {
  return Cookies.get(LxcollectLicenceId)
}

export function setLxcollectLicenceId(lxcollectlicenceid) {
  return Cookies.set(LxcollectLicenceId, lxcollectlicenceid)
}

export function removeLxcollectLicenceId() {
  return Cookies.remove(LxcollectLicenceId)
}

export function getLxcollectSubjectId() {
  return Cookies.get(LxcollectSubjectId)
}

export function setLxcollectSubjectId(lxcollectsubjectid) {
  return Cookies.set(LxcollectSubjectId, lxcollectsubjectid)
}

export function removeLxcollectSubjectId() {
  return Cookies.remove(LxcollectSubjectId)
}
export function getLxcollectQuestionId() {
  return Cookies.get(LxcollectQuestionId)
}

export function setLxcollectQuestionId(lxcollectquestionid) {
  return Cookies.set(LxcollectQuestionId, lxcollectquestionid)
}

export function removeLxcollectQuestionId() {
  return Cookies.remove(LxcollectQuestionId)
}

export function getKsLicenceId() {
  return Cookies.get(KsLicenceId)
}

export function setKsLicenceId(kslicenceid) {
  return Cookies.set(KsLicenceId, kslicenceid)
}

export function removeKsLicenceId() {
  return Cookies.remove(KsLicenceId)
}

export function getKsSubjectId() {
  return Cookies.get(KsSubjectId)
}

export function setKsSubjectId(kssubjectid) {
  return Cookies.set(KsSubjectId, kssubjectid)
}

export function removeKsSubjectId() {
  return Cookies.remove(KsSubjectId)
}

export function getKsIsSelect() {
  return Cookies.get(KsIsSelect)
}

export function setKsIsSelect(ksisselect) {
  return Cookies.set(KsIsSelect, ksisselect)
}

export function removeKsIsSelect() {
  return Cookies.remove(KsIsSelect)
}

export function getKsWrongAnswer() {
  return Cookies.get(KsWrongAnswer)
}

export function setKsWrongAnswer(kswronganswer) {
  return Cookies.set(KsWrongAnswer, kswronganswer)
}

export function removeKsWrongAnswer() {
  return Cookies.remove(KsWrongAnswer)
}

export function getKsCorrectAnswer() {
  return Cookies.get(KsCorrectAnswer)
}

export function setKsCorrectAnswer(kscorrectanswer) {
  return Cookies.set(KsCorrectAnswer, kscorrectanswer)
}

export function removeKsCorrectAnswer() {
  return Cookies.remove(KsCorrectAnswer)
}

export function getKsQuestionId() {
  return Cookies.get(KsQuestionId)
}

export function setKsQuestionId(ksquestionid) {
  return Cookies.set(KsQuestionId, ksquestionid)
}

export function removeKsQuestionId() {
  return Cookies.remove(KsQuestionId)
}

export function getKscollectLicenceId() {
  return Cookies.get(KscollectLicenceId)
}

export function setKscollectLicenceId(kscollectlicenceid) {
  return Cookies.set(KscollectLicenceId, kscollectlicenceid)
}

export function removeKscollectLicenceId() {
  return Cookies.remove(KscollectLicenceId)
}

export function getKscollectSubjectId() {
  return Cookies.get(KscollectSubjectId)
}

export function setKscollectSubjectId(kscollectsubjectid) {
  return Cookies.set(KscollectSubjectId, kscollectsubjectid)
}

export function removeKscollectSubjectId() {
  return Cookies.remove(KscollectSubjectId)
}
export function getKscollectQuestionId() {
  return Cookies.get(KscollectQuestionId)
}

export function setKscollectQuestionId(kscollectquestionid) {
  return Cookies.set(KscollectQuestionId, kscollectquestionid)
}

export function removeKscollectQuestionId() {
  return Cookies.remove(KscollectQuestionId)
}
