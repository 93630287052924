<template>
  <div>
    <div class="top">
      <el-row>
        <el-col :span="12">
          <a href="#" @click="qrCodeDialog = true">
            《安全驾驶从这里开始》二维码增值服务入口
          </a>
        </el-col>
        <el-col
          v-if="token !== undefined"
          :span="4"
          :offset="8"
          style="text-align:right;"
        >
          <a style="cursor:pointer" @click="loginout">退出登录</a>
          <span class="icon-sign-out"></span>
        </el-col>
      </el-row>
    </div>
    <div class="header-box">
      <el-row :gutter="10">
        <el-col :span="5">
          <div class="logo">
            <a href="/"
              ><img src="../../assets/images/logow.png" alt="车学堂"
            /></a>
          </div>
        </el-col>
        <el-col :span="13">
          <el-menu
            router
            :default-active="$route.path"
            class="nav"
            mode="horizontal"
            text-color="#666"
            active-text-color="#0ba2e6"
          >
            <template v-for="(item, index) in nav_menu_data">
              <el-menu-item
                :index="item.path"
                :key="index"
                v-if="item.children === undefined"
              >
                {{ item.title }}
              </el-menu-item>
              <el-submenu v-else :key="index" :index="item.path">
                <template slot="title">{{ item.title }}</template>
                <el-menu-item
                  v-for="(item1, index) in item.children"
                  :index="item1.path"
                  :key="index"
                  >{{ item1.title }}</el-menu-item
                >
              </el-submenu>
            </template>
          </el-menu>
        </el-col>
        <el-col :span="2">
          <a
            :href="path"
            class="have-login"
            v-bind:class="{ active: isActive }"
            >{{ button }}</a
          >
        </el-col>
        <el-col :span="4" style="text-align: right">
          <div class="phone-box">
            <span class="icon-phone-square"></span>400-1020-925
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      title="温馨提示"
      :visible.sync="qrCodeDialog"
      width="35%"
      center
      :close-on-click-modal="false"
      class="qr-code-dialog"
    >
      <el-row :gutter="30">
        <el-col :span="24">
          <div class="book-code">
            <img src="../../assets/images/103.jpg" alt="" />
          </div>
        </el-col>
        <el-col :span="24">
          <div class="code-info">
            <p>1、用微信扫一扫“书后二维码”，按照提示，进行注册。</p>
            <p>2、注册成功后，登录车学堂网站或者APP，可学习相关的视频。</p>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  getResult,
  removeLicenceName,
  removeParentId,
  removeResult,
  removeSubjectId,
  getChecked,
  removeChecked,
  removeIdcard,
  removeLicenceId,
  removePassword,
  removeTerm,
  removeUuid,
  getIsPay
} from '../../utils/auth'
import { loginout } from '@/api/login'
import { getSubject } from '@/api/courses'

export default {
  inject: ['reload'],
  data() {
    return {
      token: '',
      path: '',
      button: '',
      active: 0,
      input: '',
      isActive: false,
      qrCodeDialog: false,
      nav_menu_data: [
        {
          title: '首页',
          path: '/'
        },
        {
          title: '考试',
          path: '/exam'
        },
        // {
        //   title: '滴滴视频',
        //   path: '/video'
        // },
        {
          title: '安全行车',
          path: '/news',
          children: [
            { title: '新手上路', path: '/news?category_id=12' },
            { title: '经验分享', path: '/news?category_id=18' },
            { title: '特殊路况', path: '/news?category_id=19' },
            { title: '险情分析', path: '/news?category_id=20' },
            { title: '应急措施', path: '/news?category_id=21' }
          ]
        },
        {
          title: '二维码专区',
          path: '/qrcode'
        },
        {
          title: '汽车空间',
          path: '/space',
          children: [
            { title: '汽车入门', path: '/space?category_id=13' },
            { title: '新车速递', path: '/space?category_id=14' },
            { title: '维修保养', path: '/space?category_id=22' },
            { title: '汽车保险', path: '/space?category_id=23' }
          ]
        },
        {
          title: '行业资讯',
          path: '/newszx'
        }
      ]
    }
  },
  created() {
    if (getResult() === undefined) {
      this.token = undefined
    }
    this.onRouteChanged()
  },
  methods: {
    //滑块
    passcallback() {
      this.is_slider = this.$refs.Verify.isPassing
    },
    onSubmit() {
      //console.log('submit!')
    },
    //下一步
    next() {
      if (this.active++ > 3) this.active = 0
    },
    onRouteChanged() {
      let route
      let that = this
      if (getResult() === undefined) {
        // 未登录
        this.path = '/#/login'
        this.button = '登录'
        //this.$router.push({ path: '/catalogs/course' })
        route = { title: '课程', path: '/catalogs/course' }
      } else {
        if (JSON.parse(getResult()).token != null) {
          // 已登录
          this.path = '#/progress'
          this.button = '个人中心'
          this.isActive = true
          //if (JSON.parse(getResult()).is_pay >= 2) {
          if (getIsPay() >= 2) {
            route = { title: '课程', path: '/catalogs' }
            //} else if (JSON.parse(getResult()).is_pay === 1) {
          } else {
            // route = { title: '课程', path: '/catalogs/shikan' }
            route = { title: '课程', path: '/catalogs/course' }
          }
        }
      }
      that.nav_menu_data.splice(1, 0, route)
    },
    loginout() {
      if (getChecked() !== undefined) {
        if (!JSON.parse(getChecked())) {
          removeChecked()
          removeIdcard()
          removePassword()
        }
      } else {
        removeChecked()
        removeIdcard()
        removePassword()
      }
      this.$confirm('确定退出该账号吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          loginout().then(res => {
            if (res.code === 0) {
              removeResult()
              removeLicenceName()
              removeSubjectId()
              removeParentId()
              removeTerm()
              removeLicenceId()
              removeUuid()
              this.$router.push({ path: '/' })
              this.reload()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出登录'
          })
        })
    }
  },
  computed: {
    activeName: {
      get() {
        return this.active.toString()
      },
      set(newValue) {
        return newValue
      }
    }
  }
}
</script>
