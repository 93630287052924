<template>
  <div>
    <div class="footer-nav">
      <ul class="nav-list">
        <li><a href="/#/about">关于我们</a></li>
        <li><a href="/#/contactus">联系我们</a></li>
        <li><a href="/#/servicecenter">服务中心</a></li>
        <li><a>意见反馈</a></li>
        <li><a href="/#/copyright">版权声明</a></li>
        <li><a href="/#/disclaimer">免责声明</a></li>
        <li><a href="/#/useragreement">用户协议</a></li>
        <li><a href="/#/privacypolicy">隐私政策</a></li>
      </ul>
    </div>
    <div class="link-box">
      <el-row>
        <el-col :span="5" class="footer-logo">
          <img src="../../assets/images/footer_logo.png" alt="" />
        </el-col>
        <el-col :span="12" class="link-list">
          <h3>友情链接：</h3>
          <ul>
            <li><a href="https://www.mot.gov.cn/">交通运输部 </a></li>
            <li><a href="https://www.mps.gov.cn/">公安部</a></li>
            <li><a href="http://www.ccpress.com.cn/">人民交通出版社</a></li>
            <li>
              <a href="http://www.jtzyzg.org.cn/LTPU/LEAP/home/html/index.html"
                >交通职业资格网</a
              >
            </li>
            <li>
              <a href="https://www.mot.gov.cn/zizhan/zhishuJG/zhiyezigezhongxin"
                >交通运输部职业资格中心</a
              >
            </li>
            <li><a href="http://www.122.cn/">122交通网</a></li>
            <li><a href="http://www.tpri.org.cn/">交通运输部规划研究院</a></li>
            <li>
              <a href="http://www.moccats.com.cn/">交通运输公路科学研究院</a>
            </li>
            <li><a href="http://www.crta.org.cn/">中国道路运输协会</a></li>
            <li>
              <a href="http://www.rtsac.org/sites/MainSite"
                >中国道路交通安全网</a
              >
            </li>
            <li><a href="http://www.zgjtb.com/">中国交通报</a></li>
            <li><a href="http://www.21its.com/">智能交通网</a></li>
          </ul>
        </el-col>
        <el-col :span="7" class="code-box">
          <img
            src="../../assets/images/app_code.jpeg"
            width="114"
            height="114"
            alt=""
          />
          <p>
            扫一扫<br />
            下载车学堂APP学习更方便。
          </p>
        </el-col>
      </el-row>
    </div>
    <div class="copyright">
      <p>
        车学堂 Copyright@2021 人民交通出版社股份有限公司 All Rights Reserved |
        办公地址:北京市朝阳区小营北路17号 | 联系邮箱:hanchao@ccpress.com.cn |
        客服热线:400-1020-925
      </p>
      <p>
        <a style="color:#666666" href="https://beian.miit.gov.cn/">
          京ICP备15018898号-14
        </a>
      </p>
    </div>
    <div class="buy-btn" v-if="this.is_show === true">
      <span class="icon-car2"></span>
      <h2>机动车理论培训课程</h2>
      <a href="#" @click="buycourse()">立即购买</a>
    </div>
    <!--    <div class="float-service">-->
    <!--      <div :class="arrowState">-->
    <!--        <button-->
    <!--          id="btn"-->
    <!--          class="zhiCustomBtn"-->
    <!--          style="border: 0;background: none;outline:none;"-->
    <!--        >-->
    <!--          <img src="../../assets/images/service_icon.png" alt="" width="100%" />-->
    <!--        </button>-->
    <!--      </div>-->
    <!--      <ul>-->
    <!--        <li v-if="is_arrow === true">-->
    <!--          <a-->
    <!--            href="http://wpa.qq.com/msgrd?v=3&uin=2737645828&site=qq&menu=yes"-->
    <!--            target="_blank"-->
    <!--          >-->
    <!--            <span class="icon-qq"></span>-->
    <!--            QQ客服-->
    <!--          </a>-->
    <!--          <div class="qq-service">-->
    <!--            服务时间<br />工作日: 8:30-17:00<span-->
    <!--              ><img src="../../assets/images/angle_icon.png" alt=""-->
    <!--            /></span>-->
    <!--          </div>-->
    <!--        </li>-->
    <!--        <li v-if="is_arrow === true">-->
    <!--          <a href="#"><span class="icon-mobile"></span>APP下载</a>-->
    <!--          <div class="phone_icon_code">-->
    <!--            <img src="../../assets/images/app_code.jpeg" alt="" /><span-->
    <!--              ><img src="../../assets/images/angle_icon.png" alt=""-->
    <!--            /></span>-->
    <!--          </div>-->
    <!--        </li>-->
    <!--        <li v-if="is_arrow === true">-->
    <!--          <a href="#"><span class="icon-weixin"></span>关注微信</a>-->
    <!--          <div class="weixin_icon_code">-->
    <!--            <img src="../../assets/images/weixin_code.jpeg" alt="" /><span-->
    <!--              ><img src="../../assets/images/angle_icon.png" alt=""-->
    <!--            /></span>-->
    <!--          </div>-->
    <!--        </li>-->
    <!--        <li v-if="is_arrow === true">-->
    <!--          <a @click="goTop"><span class="icon-angle-top"></span>Top</a>-->
    <!--        </li>-->
    <!--        <li>-->
    <!--          <a id="dynBtn" style="padding:20px 0;" @click="artificial(arrow)">-->
    <!--            <span v-if="arrow === 'right'" class="icon-chevron-right2"></span>-->
    <!--            <span v-if="arrow === 'left'" class="icon-chevron-left2"></span>-->
    <!--          </a>-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->
    <el-dialog
      title="购买课程"
      :visible.sync="Buydialog"
      width="50%"
      center
      :before-close="courseClose"
    >
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="确认订单信息"></el-step>
        <el-step title="付款"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <div class="step-item">
        <el-tabs v-model="activeName">
          <el-tab-pane name="0">
            <div class="order-info">
              <table width="800" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <td>课程名称</td>
                    <td>课程简介</td>
                    <td>有效期</td>
                    <td width="80">价格 ( 元 )</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="150">
                      {{ this.orderinfo.licence_title }}
                    </td>
                    <td width="280">
                      {{ this.orderinfo.description }}
                    </td>
                    <td width="150">自购买之日起<span>3</span>年内有效</td>
                    <td>{{ this.orderinfo.total_fee / 100 }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="order-info-box">
                <div class="code-box">
                  <input v-model="code" placeholder="输入验证码" />
                  <div
                    class="login-code"
                    style="margin-top:4px"
                    @click="refreshCode"
                  >
                    <!--验证码-->
                    <Identify :identifyCode="identifyCode"></Identify>
                  </div>
                </div>
                <p class="money-box">
                  应付总额：<span>{{ this.orderinfo.total_fee / 100 }}</span
                  >元
                </p>
                <p>
                  <a class="confirm-btn" @click="next" style="cursor:pointer"
                    >确认订单</a
                  >
                </p>
                <div class="agreement-btn">
                  <el-checkbox v-model="checked" style="margin-right:8px"
                    >&nbsp;</el-checkbox
                  >
                  <a @click="innerVisible = true"
                    >购买即代表同意《用户购买协议》</a
                  >
                </div>
                <el-dialog
                  width="30%"
                  title="用户购买协议"
                  :visible.sync="innerVisible"
                  append-to-body
                  :before-close="agreeOn"
                >
                  <div class="agreement">
                    <p>
                      用户在购买本次车学堂收费网络课程之前，请仔细阅读本协议条款。用户应当同意本协议的全部条款，用户完成支付即表示完全接受本协议项下的全部条款。
                    </p>
                    <p>
                      1.除了用户购买本次收费网络课程并私人观看外，对于任何其他未明确授权给用户的收费网络课程及其他服务的权利、收益，由本平台或相关权利人所有。
                    </p>
                    <p>
                      2.用户应妥善保存账户注册时所填写的信息。用户账户注册时所填写的信息将成为平台验证的唯一凭证。因用户账户注册信息丢失造成不能正常登录使用的，本平台不承担任何法律责任。
                    </p>
                    <p>
                      3.用户应自行负责保管、使用、维护其在本平台的账户及账户密码。用户应对其账户及密码采取必要和有效的记忆和保密措施，对于非因本平台原因导致用户账户及密码遗忘、泄漏等所造成的用户损失，本平台无须承担与此有关的任何责任。用户发现其账户、密码遗忘、丢失后，应及时与本平台取得联系，本平台可视情况协助用户恢复其权益。
                    </p>
                    <p>
                      4.本平台收费网络课程的价格另行标明，用户点击“同意”本协议，即视为用户认可本次收费网络课程标明的价格；用户支付成功后，该项服务即时生效。
                    </p>
                    <p>
                      5.如果用户同意购买本次收费网络课程，支付成功后本协议将立即生效，用户可即时观看或在有效期内另择方便时段观看本次收费网络课程视频。如果支付失败，本协议将不发生效力。用户将无权观看本次收费网络课程视频。
                    </p>
                    <p>
                      6.本平台有权根据包括但不限于国家最新颁布或修订的相关法律法规、标准等，对网络课程进行相应的修改、更新。该等修改、更新对用户并无任何不利，不需要提前通知用户或征得用户同意，本平台视为用户接受并认可相关修改、更新行为。
                    </p>
                    <p>
                      7.因用户将个人账户及密码告知他人或与他人共享注册帐户，由此导致任何个人资料泄露的，本平台不负任何责任。
                    </p>
                    <p>
                      8.用户购买收费网络课程，只可以私人观看，不得用于商业、盈利等用途。用户同意不得对授课内容录音录像，不得将网络课程内容复制、销售、出租或授权给任何第三方。如果本平台发现用户存在上述行为，本平台将有权停止用户对本次课程的观看，该用户已支付的费用将不予退还。用户在本平台使用的账户不得出售。如果本平台发现用户擅自出售平台账户，本平台将有权终止该账户的使用，该用户已支付的费用将不予退还。
                    </p>
                    <p>
                      9.用户购买的收费网络课程有一定的有效期。超过有效期，用户将无法观看网络课程视频。用户若想获得继续观看的权限，需再次购买并支付费用。
                    </p>
                    <p>
                      10.用户在同意购买并支付成功后至有效期内首次观看课程前，可以单方解除本协议并申请退款。否则，用户一旦产生了观看记录或者虽未产生观看记录但已过有效期，则不能申请退款，请知悉。
                    </p>
                    <p>
                      11.如果用户在同意购买并支付费用时，使用支付宝或微信形式付款，则用户根据前条申请退款时，本平台将视用户付款方式将相关费用退至用户的支付宝或微信账户内。由于支付宝和微信会产生手续费，该等手续费应由用户承担，所以退费时会被扣除1%的费用。
                    </p>
                  </div>
                  <div class="confirm-box">
                    <a
                      href="#"
                      class="con-btn"
                      @click="agree"
                      style="cursor:pointer"
                      >同意</a
                    >
                  </div>
                </el-dialog>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="1">
            <div class="order-two-content">
              <div class="order-two-left">
                <h2>订单信息</h2>
                <ul>
                  <li>
                    课程名称：<span id="subject">{{
                      this.orderinfo.licence_title
                    }}</span>
                  </li>
                  <li>
                    交易金额：<span id="total_fee">{{
                      this.orderinfo.total_fee / 100
                    }}</span
                    >元
                  </li>
                  <li>购买日期：{{ this.orderinfo.create_time }}</li>
                  <li>
                    有&nbsp;&nbsp;效&nbsp;期：{{ this.orderinfo.valid_time }}
                  </li>
                  <li>
                    订&nbsp;&nbsp;单&nbsp;号：<span id="out_trade_no">
                      {{ this.orderinfo.out_trade_no }}</span
                    >
                  </li>
                </ul>
              </div>
              <div class="order-two-right">
                <h2>付款方式</h2>
                <ul class="choice-ppayment">
                  <li
                    id="alipay"
                    @click="payType(item.paytype)"
                    class="active"
                    style="cursor:pointer;border: 1px solid #ccc;"
                    v-for="(item, index) in paytype"
                    :key="index"
                  >
                    <img
                      v-if="item.paytype === 2"
                      src="@/assets/images/payment_zfb.jpg"
                      alt=""
                    />
                    <img
                      v-if="item.paytype === 2 && type === 2"
                      src="@/assets/images/payment_icon.png"
                      alt=""
                      class="choice-icon"
                    />
                    <img
                      v-if="item.paytype === 1"
                      src="@/assets/images/payment_wx.jpg"
                      alt=""
                    />
                    <img
                      v-if="item.paytype === 1 && type === 1"
                      src="@/assets/images/payment_icon.png"
                      alt=""
                      class="choice-icon"
                    />
                  </li>
                </ul>
              </div>
              <div class="confirm-box">
                <a class="con-btn" @click="next" style="cursor:pointer"
                  >马上付款</a
                >
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="2">
            <div class="change-alt">
              <span v-if="pay_status == 0" class="icon-checkmark1"></span>
              <span v-if="pay_status == 1" class="icon-cancel1"></span>
              <p v-if="pay_status == 0">付款成功！</p>
              <p v-if="pay_status == 1">付款失败！</p>
            </div>
            <div class="confirm-box"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
    <el-dialog
      title="微信支付"
      :visible.sync="wxpaydialog"
      width="40%"
      center
      :before-close="handleClose"
    >
      <div class="wechat-pay">
        <h2>
          支付金额：
          <span>{{ this.orderinfo.total_fee / 100 }}</span>
          元
        </h2>
        <div>
          <img :src="this.wximage" />
          <p>请微信扫码支付</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getResult,
  getTerm,
  removeResult,
  getLicenceName,
  removeIsPay,
  setIsPay,
  getIsPay
} from '../../utils/auth'
import { courserule } from '@/api/courses'
import { createorder, confirmorder, selectorder } from '@/api/order'
import Identify from '@/components/identify'
import { MessageBox } from 'element-ui'
export default {
  components: { Identify },
  inject: ['reload'],
  data() {
    return {
      term: 0,
      licence_id: 0,
      is_show: false,
      ruleData: [],
      active: 0,
      Buydialog: false,
      identifyCodes: '1234567890',
      identifyCode: '',
      orderinfo: [],
      paytype: [],
      licence_name: '',
      code: '',
      innerVisible: false,
      type: 2,
      wxpaydialog: false,
      wximage: '',
      htmls: '',
      inter: '',
      checked: true,
      pay_status: 1,
      start: 120,
      end: 3,
      mid: 24,
      arrow: 'right',
      is_arrow: true,
      scrollTop: 0,
      arrowState: 'showarrow'
    }
  },
  created() {
    if (getResult() === undefined) {
      // this.$alert('您没有登录，请登录后再访问', '登录提示', {
      //   confirmButtonText: '重新登录',
      //   callback: action => {
      //     removeResult()
      //     location.href = '/login'
      //   }
      // })
    } else {
      this.licence_id = JSON.parse(getResult()).licence_id
      this.is_pay = getIsPay()
      this.term = getTerm()
      this.getRule()
      this.licence_name = getLicenceName()
    }
  },
  methods: {
    getRule() {
      let params = {
        licence_id: this.licence_id,
        term: this.term
      }
      courserule(params).then(res => {
        if (res.code === 0) {
          this.ruleData = res.result
          // this.ruleData = {
          //   buybtn: 2,
          //   check_interval: 900,
          //   checked: 0,
          //   course_front_ad: 0,
          //   course_study_ad: 0,
          //   drag: 0,
          //   duration_limit: 10800,
          //   exam_button_word: 0,
          //   exam_duration: 0,
          //   exam_isshow: 0,
          //   exam_limit: 0,
          //   exam_pass_template: 0,
          //   exam_submit_content: "",
          //   index_pay_window: 0,
          //   is_collection: 1,
          //   is_pay: 1,
          //   jump: 0,
          //   log_duration_limit: 0,
          //   logout: 1,
          //   order: 1,
          //   photo_num: 0,
          //   playing: 1,
          //   push_period_isshow: 1,
          //   rule_content: "1、请按照顺序学习课程\n2、每天学习课程时间不得超过180分钟\n3、按照要求进行人脸拍照",
          //   rule_name: "2-江西九江初学-购买",
          //   score_isshow: 0,
          //   start: 0,
          //   stop: 0,
          //   study_time_zone: [],
          //   subject1_duration_limit: 162000,
          //   tiku_referee: 0,
          //   unit: 2700,
          // }
          if (this.is_pay < 2 && this.ruleData.buybtn === 1) {
            this.is_show = true
          }
        } else if (res.code === -1) {
          MessageBox.alert('登录过期，请登录后再访问', '登录提示', {
            confirmButtonText: '重新登录',
            callback: action => {
              removeResult()
              location.href = '#/login'
            }
          })
        }
      })
    },
    //同意
    agree() {
      this.checked = true
      this.innerVisible = false
    },
    agreeOn() {
      this.innerVisible = false
    },
    courseClose() {
      clearInterval(this.selTimer)
      this.active = 0
      this.code = ''
      this.Buydialog = false
    },
    // 重置验证码
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    //付款方式
    payType(type) {
      this.type = type
    },
    //生成订单
    buycourse() {
      // 初始化验证码
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
      createorder({ url: 'https://www.chexuetang.cn/#/catalogs' }).then(res => {
        if (res.code === 0) {
          this.orderinfo = res.result
          this.paytype = res.result.paytype
          this.type = res.result.paytype[0].paytype
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
      this.Buydialog = true
    },
    handleClose(done) {
      this.active = 2
      clearInterval(this.selTimer)
      this.wxpaydialog = false
    },
    //下一步
    next() {
      if (this.active === 0) {
        if (this.code !== '') {
          if (this.code !== this.identifyCode.toLowerCase()) {
            this.$message.error('请填写正确验证码')
          } else {
            if (this.checked !== true) {
              this.$message.error('请先同意本协议')
            } else {
              this.active++
            }
          }
        } else {
          this.$message.error('请填写验证码')
        }
      } else if (this.active === 1) {
        for (let i = 0; i < this.orderinfo['paytype'].length; i++) {
          if (this.orderinfo['paytype'][i]['paytype'] == this.type) {
            this.mid = this.orderinfo['paytype'][i]['mid']
          }
        }
        let params = {
          out_trade_no: this.orderinfo.out_trade_no,
          paytype: this.type,
          mid: this.mid,
          platid: 2
        }
        confirmorder(params).then(res => {
          if (res.code === 0) {
            this.selOrder()
            if (this.type === 1) {
              this.wxpaydialog = true
              this.wximage = res.result
            }
            if (this.type === 2) {
              let routerData = this.$router.resolve({
                path: '/applyText',
                query: {
                  htmls: res.result
                }
              })
              this.htmls = res.result
              //打开新页面
              window.open(routerData.href, '_ blank')
            }
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else if (this.active === 2) {
        this.active = 0
      }
    },
    //查询订单
    selOrder() {
      this.selTimer = setInterval(() => {
        if (this.start <= this.end) {
          clearInterval(this.selTimer)
          this.$message({
            message: '支付超时！',
            type: 'error',
            onClose: () => {
              this.wxpaydialog = false
            }
          })
        }
        let params = {
          out_trade_no: this.orderinfo.out_trade_no,
          platid: 2
        }
        selectorder(params).then(res => {
          this.start = this.start - this.end
          if (res.code == 0) {
            this.pay_status = 0
            this.wxpaydialog = false
            this.active = 2
            clearInterval(this.selTimer)
            this.reload()
            this.$router.push({ path: '/catalogs' })
            removeIsPay()
            setIsPay('2')
          } else if (res.code == 1) {
            this.pay_status = 1
          } else {
            clearInterval(this.selTimer)
            this.$message({
              message: '系统错误！',
              type: 'error'
            })
          }
        })
      }, this.end * 1000)
    },
    //客服
    artificial(arrow) {
      if (arrow === 'right') {
        this.arrow = 'left'
        this.is_arrow = false
        this.arrowState = 'nonearrow'
      }
      if (arrow === 'left') {
        this.arrow = 'right'
        this.is_arrow = true
        this.arrowState = 'showarrow'
      }
    },
    //回到顶部
    goTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    scrollToTop() {
      const that = this
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      that.scrollTop = scrollTop
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  computed: {
    activeName: {
      get() {
        return this.active.toString()
      },
      set(newValue) {
        return newValue
      }
    }
  }
}
</script>
<style scoped lang="less">
.nonearrow {
  width: 86px;
  height: 105px;
  display: none;
}
.showarrow {
  width: 86px;
  height: 105px;
}
</style>
