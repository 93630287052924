import request from '@/utils/request'

const VERSION = process.env.VUE_APP_VERSION

export function getDaily(data) {
  return request({
    url: VERSION + '/daily/list',
    method: 'get',
    params: data
  })
}
export function gethandler(data) {
  return request({
    url: VERSION + '/daily/handler',
    method: 'post',
    data: data
  })
}
export function getprogress(data) {
  return request({
    url: VERSION + '/study/progress',
    method: 'get',
    params: data
  })
}
