<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      //控制视图是否显示的变量
      isRouterAlive: true
    }
  },
  methods: {
    reload() {
      //先关闭，
      this.isRouterAlive = false
      this.$nextTick(function() {
        //再打开
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="less" rel="stylesheet/less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #666;
  overflow-x: hidden;
}
</style>
