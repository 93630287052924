import request from '@/utils/request'

const VERSION = process.env.VUE_APP_VERSION

//用户详情
export function userinfo() {
  return request({
    url: VERSION + '/user/info',
    method: 'get'
  })
}
//用户车型
export function userlicence() {
  return request({
    url: VERSION + '/user/licence',
    method: 'get'
  })
}
//绑定二维码
export function ewmbind(data) {
  return request({
    url: VERSION + '/user/scanCode',
    method: 'post',
    params: data
  })
}
//发送短信
export function getcode(data) {
  return request({
    url: VERSION + '/user/sms',
    method: 'post',
    data: data
  })
}
//短信验证
export function verifycode(data) {
  return request({
    url: VERSION + '/user/smsVerify',
    method: 'post',
    params: data
  })
}
//修改密码
export function updatepassword(data) {
  return request({
    url: VERSION + '/user/password',
    method: 'put',
    params: data
  })
}
//人脸采集
export function collection(data) {
  return request({
    url: VERSION + '/user/collection',
    method: 'post',
    data: data
  })
}
//订单列表
export function orderlist() {
  return request({
    url: VERSION + '/order/list',
    method: 'get'
  })
}
//用户识别验证
export function userverify(data) {
  return request({
    url: VERSION + '/user/verify',
    method: 'post',
    params: data
  })
}
//找回密码
export function findpassword(data) {
  return request({
    url: VERSION + '/user/findpwd',
    method: 'put',
    params: data
  })
}
//修改车型
export function changelicence(data) {
  return request({
    url: VERSION + '/user/info',
    method: 'put',
    params: data
  })
}
//学员信息同步
export function syncuser() {
  return request({
    url: VERSION + '/user/sync',
    method: 'put'
  })
}
//激活
export function userActive(data) {
  return request({
    url: VERSION + '/user/active',
    method: 'put',
    params: data
  })
}
//新增车型
export function addlicence(data) {
  return request({
    url: VERSION + '/user/licence',
    method: 'post',
    params: data
  })
}
export function guangdong(data) {
  return request({
    url: VERSION + '/guangdong/userinfo',
    method: 'post',
    params: data
  })
}
export function updateImg(data) {
  return request({
    url: VERSION + '/guangdong/updateImg',
    method: 'post',
    params: data
  })
}
