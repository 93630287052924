import request from '@/utils/request'

const VERSION = process.env.VUE_APP_VERSION

export function getCourses(data) {
  return request({
    url: VERSION + '/course/getCourseFrame',
    method: 'get',
    params: data
  })
}
export function getSubject(data) {
  return request({
    url: VERSION + '/course/getSubjectList',
    method: 'get',
    params: data
  })
}
//提交课程学习记录
export function studydaily(data) {
  return request({
    url: VERSION + '/course/ciRecoder',
    method: 'post',
    params: data
  })
}
//课程规则
export function courserule(data) {
  return request({
    url: VERSION + '/course/rule',
    method: 'get',
    params: data
  })
}
