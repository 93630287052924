<template>
  <div class="content">
    <div class="content-header" style="height:115px">
      <div class="header-info">
        <el-row>
          <el-col :span="4">
            <div class="user-pic">
              <!-- <img :src="avatar" alt="" /> -->
              <el-image
                v-if="this.avatar != ''"
                style="width: 100%; height: 100%"
                :src="avatar"
                fit="cover"
              />
              <el-image
                v-if="this.avatar == ''"
                style="width: 100%; height: 100%"
                src="https://img0.drivedu.com.cn/default.jpg"
                fit="cover"
              />
            </div>
            <p class="placeholder"></p>
          </el-col>
          <el-col :span="12" class="user-info">
            <h2>{{ real_name }}</h2>
            <p>
              <span class="icon-location2"></span>
              <el-select
                @change="getLicenceid($event)"
                v-model="licence_id"
                class="changeLicence"
              >
                <el-option
                  v-for="item in licenceData"
                  :key="item.licence_id"
                  :label="item.licence_name"
                  :value="item.licence_id"
                >
                </el-option>
              </el-select>
              ·
              {{ jiaxiao_name }}
            </p>
          </el-col>
          <!-- <el-col :span="5" :offset="3" class="study-time">
            <el-row>
              <el-col :span="12">
                <p>
                  <span>{{ this.total }}h</span>总学时
                </p>
              </el-col>
              <el-col :span="12">
                <p>
                  <span>{{ parseInt(this.daily_duration / 3600) }}h</span>已完成
                </p>
              </el-col>
            </el-row>
          </el-col> -->
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { getDaily } from '@/api/daily'
import { getResult, getLicenceName, getSubjectId } from '@/utils/auth'
import {
  getParentId,
  getTerm,
  getTermi,
  removeResult,
  setIsPay,
  setLicenceName,
  setParentId,
  setResult,
  setSubjectId,
  setTerm
} from '../../utils/auth'
import { changelicence, userlicence } from '../../api/user'
import { getprogress } from '../../api/daily'
import { getSubject } from '../../api/courses'
export default {
  inject: ['reload'],
  data() {
    return {
      finish_duration: 0,
      daily_duration: 0,
      total: 0,
      avatar: '',
      real_name: '',
      licence_title: '',
      licence_name: '',
      licenceData: [],
      licence_id: 0,
      jiaxiao_name: '',
      subject_id: '',
      term: ''
    }
  },
  components: {},
  created() {
    this.getInfo()
    this.getLicence()
    this.getProgress()
  },
  methods: {
    //用户信息
    getInfo() {
      if (getResult() === undefined) {
        this.$alert('登录过期，请登录后再访问', '登录提示', {
          confirmButtonText: '重新登录',
          callback: action => {
            removeResult()
            location.href = '#/login'
          }
        })
      } else {
        this.avatar = JSON.parse(getResult()).avatar
        this.real_name = JSON.parse(getResult()).real_name
        this.licence_title = JSON.parse(getResult()).licence_title
        this.jiaxiao_name = JSON.parse(getResult()).jiaxiao_name
        this.term = JSON.parse(getResult()).term
        this.licence_ids = JSON.parse(getResult()).licence_id
        this.licence_name = getLicenceName()
        this.parent_id = getParentId()
        this.subject_id = getSubjectId()
      }
      //this.getDaily()
    },
    //车型
    getLicence() {
      userlicence().then(res => {
        if (res.code === 0) {
          for (let i = 0; i < res.result.length; i++) {
            // if (this.parent_id == res.result[i].parent_id) {
            //   this.licenceData = res.result[i].data
            //   for (let i = 0; i < this.licenceData.length; i++) {
            //     if (this.licence_id === this.licenceData[i].licence_id) {
            //       setLicenceName(this.licenceData[i].licence_name)
            //     }
            //   }
            // }
            for (let s = 0; s < res.result[i].data.length; s++) {
              if (this.licence_ids == res.result[i].data[s].licence_id) {
                this.licenceData = res.result[i].data
                this.licence_id = res.result[i].data[s].licence_id
                setLicenceName(res.result[i].data[s].licence_name)
              }
            }
          }
        }
      })
    },
    //下拉框选中车型
    getLicenceid(e) {
      let obj = {}
      obj = this.licenceData.find(item => {
        return item.licence_id === e
      })
      for (let i = 0; i < this.licenceData.length; i++) {
        if (e === this.licenceData[i].licence_id) {
          setIsPay(this.licenceData[i].term[0].is_pay)
        }
      }
      let arr = JSON.parse(getResult())
      arr.licence_id = e
      arr.licence_title = obj.licence_title
      setResult(arr)
      setLicenceName(obj.licence_name)
      let params = {
        licence_id: e,
        licence_title: obj.licence_title,
        term: 1,
        study_no: JSON.parse(getResult()).study_no
      }
      changelicence(params).then(res => {
        if (res.code == 0) {
          setTerm(1)
          this.getProgress()
          getSubject({ licence_id: JSON.parse(getResult()).licence_id }).then(
            res => {
              if (res.code === 0) {
                this.subjectdata = res.result.subject_list
                setSubjectId(this.subjectdata[0].subject_id)
                this.reload()
              }
            }
          )
        }
        if (res.code !== 0) {
          this.$message({
            message: '车型更换失败！',
            type: 'warning'
          })
        }
      })
    },
    getProgress() {
      let params = {
        licence_id: JSON.parse(getResult()).licence_id,
        term: getTerm()
      }
      getprogress(params).then(res => {
        this.finish_duration = 0
        this.daily_duration = 0
        if (res.code === 0) {
          this.total = parseInt(res.result.total / 3600)
          for (let i = 0; i < res.result.duration.finish_duration.length; i++) {
            this.finish_duration += parseInt(
              res.result.duration.finish_duration[i].duration
            )
          }
          for (
            let i = 0;
            i < res.result.duration.daily_study_duration.length;
            i++
          ) {
            this.daily_duration += parseInt(
              res.result.duration.daily_study_duration[i].duration
            )
          }
        }
      })
    }
  }
}
</script>
