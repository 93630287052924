import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
import { getResult, removeResult } from '@/utils/auth'
import md5 from 'js-md5'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 500000 // request timeout
})
const timestamp = Math.round(new Date() / 1000)
const key = 'ItZihDVLLumrLBcFR5PuEDUwbVvs8DIAsHDVT989'

function sign(params) {
  let str = ''
  if (params) {
    params = objKeySort(params)
    for (const k in params) {
      str += k + '=' + params[k] + '&'
    }
    str
      .split('&')
      .sort()
      .join('&')
  }
  str += 'timestamp=' + timestamp
  return md5(md5(str) + key)
}

/**
 *
 * @param arys
 * @returns object
 */
function objKeySort(arys) {
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  const newkey = Object.keys(arys).sort()
  //console.log('newkey='+newkey);
  const newObj = {} //创建一个新的对象，用于存放排好序的键值对
  for (let i = 0; i < newkey.length; i++) {
    //遍历newkey数组
    newObj[newkey[i]] = arys[newkey[i]]
    //向新创建的对象中按照排好的顺序依次增加键值对
  }
  return newObj //返回排好序的新对象
}

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['Accept'] = 'application/prs.cxt.v3+json'
    config.headers['deviceos'] = 'pc'
    config.headers['deviceid'] = '2en34rt355refc55'
    config.headers['timestamp'] = timestamp
    // console.log(111)
    // console.log(config)
    config.headers['sign'] = sign(config.data ? config.data : config.params)
    // config.headers['token'] = 'b17bf094014102a7468330323632cdb7'
    if (getResult() !== undefined) {
      let token = JSON.parse(getResult()).token || store.getters.token
      if (token) {
        config.headers['token'] = JSON.parse(getResult()).token
      }
    }
    return config
  },
  error => {
    // do something with request error
    //console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (response.status !== 200 || res.code === -2) {
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 100000
      // })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === -2) {
        // to re-login
        MessageBox.alert('登录过期，请登录后再访问', '登录提示', {
          confirmButtonText: '重新登录',
          callback: action => {
            removeResult()
            location.href = '#/login'
          }
        })
        return false
      }
      // return Promise.reject(new Error(res.message || 'Error'))
    } else {
      if (res.code === 403) {
        Message({
          message: res.message || 'Error',
          type: 'warning',
          duration: 5 * 100000
        })
      }
      return res
    }
  },
  error => {
    //console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 100000
    })
    return Promise.reject(error)
  }
)

export default service
