<template>
  <div class="app-wrapper">
    <div class="main-container">
      <Header />
      <div class="content">
        <persona-top />
        <el-container class="content-box">
          <aside-menu></aside-menu>
          <aside-main></aside-main>
        </el-container>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'
import personaTop from '@/components/layout/personaTop'
import asideMenu from '@/components/layout/asideMenu'
import asideMain from '@/components/layout/asideMain'

export default {
  name: 'profileLayout',
  components: {
    Footer,
    Header,
    personaTop,
    asideMenu,
    asideMain
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    }
  }
}
</script>
