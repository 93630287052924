<template>
  <div class="app-wrapper">
    <div class="main-container">
      <Header />
      <app-main></app-main>
      <Footer />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'
import AppMain from '@/components/layout/appMain'

export default {
  name: 'layout',
  components: {
    Header,
    Footer,
    AppMain
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    }
  }
}
</script>
